import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { is } from 'date-fns/locale';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflow: 'hidden',
  },
  content: {
    height: 'auto !important',
    maxHeight: 'calc(100vh - 400px) !important',
  },
}));

const ConfirmationDialog = ({ open, currentDialog, handleCloseDialog, handleConfirmDialog, spinnerVisible }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dialogMetaData = [
    {
      title: t('Finalize Note'),
      message: t('Are you sure you want to finalize this note? Once finalized, it cannot be edited.'),
      confirmText: t('Finalize'),
      cancelText: t('Cancel'),
      actionType: 'finalize',
    },
    {
      title: t('Unsaved Changes'),
      message: t('You have unsaved changes. Are you sure you want to leave?'),
      confirmText: t('Leave'),
      cancelText: t('Stay'),
      actionType: 'leave',
    },
    {
      title: t('Delete Note'),
      message: t('Are you sure you want to delete this note? This action cannot be undone.'),
      confirmText: t('Delete'),
      cancelText: t('Cancel'),
      actionType: 'delete',
    },
  ];
  const dialogData = dialogMetaData.find((dialog) => dialog.actionType === currentDialog);

  if (!dialogData) return null;

  return (
    <Dialog open={open} maxWidth="sm" className={classes.dialog} disableEnforceFocus fullWidth>
      <DialogTitle>{t(dialogData.title)}</DialogTitle>
      <DialogContent className={classes.content} dividers>
        {spinnerVisible ? (
          <Box display="flex" alignItems="center">
            <CircularProgress size={24} />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {t('Finalizing note...')}
            </Typography>
          </Box>
        ) : (
          <Typography>{t(dialogData.message)}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} disabled={spinnerVisible}>
          {t(dialogData.cancelText)}
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirmDialog} disabled={spinnerVisible}>
          {t(dialogData.confirmText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
