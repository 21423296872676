import moment from 'moment';
import i18n from '../../i18n';

export const ALERT_TRIGGER = {
  V_BP: i18n.t('Blood Pressure'),
  V_WT: i18n.t('Weight'),
  V_BG: i18n.t('Blood Glucose'),
  V_HR: i18n.t('Heart Rate'),
  V_WT_F: i18n.t('Weight Fluctuation'),
  DQ: i18n.t('Questionnaire'),
  D: i18n.t('Diagnosis'),
  M_LDL: i18n.t('LDL'),
  M_potassium: i18n.t('Potassium'),
  M_sodium: i18n.t('Sodium'),
  M_ALT: i18n.t('ALT'),
  M_temperature: i18n.t('Temperature'),
  M_spo2: i18n.t('Oxygen Saturation'),
  M_HbA1c: i18n.t('HbA1c'),
  M_AST: i18n.t('AST'),
  M_LVEF: i18n.t('LVEF'),
  M_tobaccoQA: i18n.t('Tobacco smoking status'),
  M_creatinine: i18n.t('Creatinine'),
  M_INR: i18n.t('INR'),
  M_CK: i18n.t('CK'),
  M_BNP: i18n.t('BNP'),
  M_microalbuminuria: i18n.t('Microalbuminuria'),
  M_NYHA: i18n.t('NYHA'),
  M_NTproBNP: i18n.t('NTproBNP'),
  M_GFReMDRD: i18n.t('GFRe (MDRD)'),
  M_referenceLVEF: i18n.t('Reference LVEF'),
  M_referenceNYHA: i18n.t('Reference NYHA'),
  M_heigth: i18n.t('Height'),
  M_dryWeight: i18n.t('Dry Weight'),
  M_GFReCockroft: i18n.t('GFRe (Cockroft-Gault)'),
};

export const ALERT_TRIGGER_URL = {
  V_BP: 'vitals/bloodPressure',
  V_WT: 'vitals/weight',
  V_BG: 'vitals/bloodGlucose',
  V_HR: 'vitals/heartRate',
  M_LDL: 'lab-results/LDL',
  M_potassium: 'lab-results/potassium',
  M_sodium: 'lab-results/sodium',
  M_ALT: 'lab-results/ALT',
  M_temperature: 'vitals/temperature',
  M_spo2: 'vitals/spo2',
  M_HbA1c: 'lab-results/HbA1c',
  M_AST: 'lab-results/AST',
  M_LVEF: 'clinical-data/LVEF',
  M_tobaccoQA: 'clinical-data/tobaccoQA',
  M_creatinine: 'lab-results/creatinine',
  M_INR: 'lab-results/INR',
  M_CK: 'lab-results/CK',
  M_BNP: 'lab-results/BNP',
  M_microalbuminuria: 'lab-results/microalbuminuria',
  M_NYHA: 'clinical-data/NYHA',
  M_NTproBNP: 'lab-results/NTproBNP',
  M_GFReMDRD: 'lab-results/GFReMDRD',
  M_referenceLVEF: 'clinical-data/referenceLVEF',
  M_referenceNYHA: 'clinical-data/referenceNYHA',
  M_heigth: 'clinical-data/heigth',
  M_dryWeight: 'clinical-data/dryWeight',
  M_GFReCockroft: 'lab-results/GFReCockroft',
};

export const ALERT_CONFIGS = {
  alert: {
    label: 'Alerts',
    pieLabel: i18n.t('Active alerts'),
    types: {
      OT: {
        label: i18n.t('Outside of Thresholds data'),
        color: '#052F5F',
      },
      HR: {
        label: i18n.t('High Risk Level Based on Questionnaire Alert'),
        color: '#005377',
      },
      VF: {
        label: i18n.t('Vital Fluctuation'),
        color: '#06A77D',
      },
      MC: {
        label: i18n.t('Medication Contraindication'),
        color: '#D5C67A',
      },
      RL: {
        label: i18n.t('Rule Based Alert'),
        color: '#F1A208',
      },
    },
  },
  reminder: {
    label: i18n.t('Reminders'),
    pieLabel: i18n.t('Active Reminders'),
    types: {
      NS: {
        label: i18n.t('Should start next titration step'),
        color: '#f48c06',
      },
      MT: {
        label: i18n.t('Suggested medication not taken'),
        color: '#e85d04',
      },
      MI: {
        label: i18n.t('Missing information - cannot generate treatment plan'),
        color: '#ffba08',
      },
    },
  },
};

export const mergeActiveAndClearedAlerts = (actives, cleared) => (
  [...actives, ...cleared].sort((a, b) => (
    moment(a.last_modify).diff(moment(b.last_modify))
  ))
);

export const countActiveAlerts = (alerts, startDate, endDate) => {
  let activeAlerts = 0;
  let parsedAlerts = [];

  alerts.forEach((alert) => {
    if (alert.status === 'active') {
      activeAlerts += 1;
      if (moment(alert.last_modify).isAfter(moment(startDate))) {
        parsedAlerts.push({
          x: moment(alert.create_date),
          y: activeAlerts,
        });
      }
    } else {
      if (parsedAlerts.length === 0) {
        parsedAlerts.push({
          x: moment(startDate),
          y: activeAlerts + 1,
          status: 'cleared',
        });
      } else {
        parsedAlerts = parsedAlerts.map((item) => ({
          ...item,
          y: item.y + 1,
        }));
      }
      parsedAlerts.push({
        x: moment(alert.last_modify),
        y: activeAlerts,
        status: 'cleared',
      });
    }
  });

  if (parsedAlerts.length === 1) {
    parsedAlerts.push({
      x: moment(endDate),
      y: activeAlerts,
    });
  }

  return parsedAlerts;
};

export const parseAlertByType = (alerts, type) => {
  const alertsByType = {};
  alerts.forEach((alert) => {
    if (alert.alert_type in ALERT_CONFIGS[type].types) {
      if (!alertsByType[alert.alert_type]) {
        alertsByType[alert.alert_type] = [alert];
      } else {
        alertsByType[alert.alert_type].push(alert);
      }
    }
  });
  return alertsByType;
};
