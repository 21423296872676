import React,{forwardRef} from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { formatPhoneNumber } from '../../../utils';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../redux/api';
import moment from 'moment';

const PrintableNote = forwardRef(({ note }, ref) => {
    const { t } = useTranslation();
    const { account } = greyboxApiActions;
    const { data, isLoading } = account.get(note?.subject.reference.split('/')[1], { skip: !note });
  
    if (isLoading || !note) {
      return null;
    }
  
    return (
      <Box sx={{ p: 4 }} ref={ref}>
        <Box display="flex">
          <div>
            <Typography variant="h5">{note.summary}</Typography>
          </div>
          <Box sx={{ ml: 'auto' }}>
            <Typography variant="caption">
              {`${t('Created on')} ${moment(note.date).format('lll')}`}
              <>
                <br />
                {`${t('By')} ${note.assessor.display.firstName} ${note.assessor.display.lastName}`}
              </>
            </Typography>
            <br />
            <Typography variant="caption">
              {`${t('For')} ${data.firstName} ${data.lastName}`}
              <br />
              {data.birthDate && (
                <>
                  {`${t('Birth Date')}: ${moment(data.birthDate).format('ll')}`}
                  <br />
                </>
              )}
              {data.phoneNumber && (
                `${t('Phone')}: ${formatPhoneNumber(data.phoneNumber)}`
              )}
            </Typography>
            <br />
            <Typography variant="caption">
              {data.hin && (
                `${data.hin.hin_number}`
              )}
            </Typography>
          </Box>
        </Box>
        <ReactMarkdown>{note?.description}</ReactMarkdown>
      </Box>
    );
  });

  export default PrintableNote;
  