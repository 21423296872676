export const styles = {
    minHeight: '500px',
    mt: '20px',
    '& .ql-container': {
      minHeight: '500px',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      borderColor: 'transparent',
    },
    '& .quill ': {
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      borderColor: 'transparent',
    },
    '& .ql-toolbar': {
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
      borderTopColor: 'transparent',
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
    },
    '& .ql-editor.ql-blank::before': { color: '#999 !important' },
    '& .ql-stroke': { stroke: `#999 !important` },
    '& .ql-fill': {
      fill: '#999 !important',
    },
    '& .ql-picker-label': {
      color: '#999 !important',
    },
    '& .ql-picker-label:hover': {
      color: '#2B5766 !important',
    },
    '& button:hover .ql-stroke, .ql-picker-label:hover .ql-stroke': {
      fill: 'none',
      stroke: '#2B5766 !important',
    },
    '& .ql-active .ql-stroke': {
      fill: 'none',
      stroke: '#2B5766 !important',
    },
    '& button:hover .ql-fill, .ql-picker-label:hover .ql-fill': {
      fill: '#2B5766 !important',
      stroke: 'none',
    },
    '& .ql-active .ql-fill': {
      fill: '#2B5766 !important',
      stroke: 'none',
    },
};
