import React from 'react';
import { Box, Skeleton, Grid, Container, Paper } from '@mui/material';

const ClinicalNoteSkeleton = ({ paperClass }) => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 3 }}>
        <Skeleton variant="text" width="30%" height={32} />
      </Box>
      <Paper className={paperClass}>
        <Box sx={{ p: 3 }}>
          <Box sx={{ mb: 3 }}>
            <Skeleton variant="text" width="40%" height={24} />
            <Skeleton variant="rectangular" height={40} sx={{ mt: 1, borderRadius: 1 }} />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Skeleton variant="text" width="40%" height={24} />
            <Skeleton variant="rectangular" height={420} sx={{ mt: 1, borderRadius: 1 }} />
          </Box>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Skeleton variant="rectangular" width={100} height={40} sx={{ borderRadius: 1 }} />
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" width={100} height={40} sx={{ borderRadius: 1 }} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default ClinicalNoteSkeleton;
