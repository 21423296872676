import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { styles } from './styles';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: '#d32f2f',
    marginTop: '8px',
    marginLeft: '14px',
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  readOnlyDscripton: {
    minHeight: '50vh',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: 'auto',
    fontFamily: theme.typography.fontFamily,
  },
}));

const QuillWrapper = ({ defaultValue, onChange }) => {
  const theme = useTheme();
  const [borderColor, setBorderColor] = useState(theme.palette.border.main);
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const classes = useStyles();
  const previousContent = useRef(defaultValue || '');

  const handleQuillChange = (content) => {
    if (content !== previousContent.current) {
      previousContent.current = content;
      onChange(content);
      if (previousContent.current === '<p><br></p>' || previousContent.current === '') {
        setError(t('Description is required'));
        setBorderColor('#d32f2f');
      } else {
        setError('');
        setBorderColor(theme.palette.border.main);
      }
    }
  };

  const handleFocus = () => {
    setBorderColor(theme.palette.primary.main);
  };

  const handleBlur = () => {
    if (previousContent.current === '<p><br></p>' || previousContent.current === '') {
      setBorderColor('#d32f2f');
      setError(t('Description is required'));
    } else {
      setBorderColor(theme.palette.border.main);
      setError('');
    }
  };

  const modules = {
    toolbar: [[{ header: [1, 2, 3, false] }], ['bold', 'italic'], [{ list: 'ordered' }, { list: 'bullet' }]],
  };

  return (
    <Box sx={{ ...styles, '& .ql-editor': { fontFamily: classes.readOnlyDscripton.fontFamily, minHeight: '500px' } }}>
      <ReactQuill
        theme="snow"
        placeholder="Description*"
        defaultValue={defaultValue || ''}
        onChange={handleQuillChange}
        modules={modules}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ minHeight: '500px', border: `2px solid ${borderColor}`, borderRadius: '4px' }}
      />
      {error && <Typography className={classes.errorMessage}>{error}</Typography>}
    </Box>
  );
};

export default QuillWrapper;
