import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  TextField,
  Typography,
  Snackbar,
  Alert,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useReactToPrint } from 'react-to-print';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import { debounce } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IoCloudDone, IoCloudOffline } from 'react-icons/io5';
import ConfirmationDialog from './ConfirmationDialog';
import PrintableNote from './PrintableNote';
import GenerateClinicalNoteReportDialog from './GenerateClinicalNoteReportDialog';
import Markdown from 'markdown-to-jsx';
import ClinicalNoteSkeleton from './ClinicalNoteSkeleton';
import QuillWrapper from '../components/QuillTextEditor/QuillWrapper';
import TurndownService from 'turndown';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const turndownService = new TurndownService({ headingStyle: 'atx' });

const useStyles = makeStyles((theme) => ({
  spacer: {
    marginBottom: theme.spacing(2),
  },
  editorContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(2, 2, 2, 2),
    minHeight: '100px',
    marginBottom: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  readOnlySummary: {
    minHeight: '20px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
  },
  markdown: {
    padding: '0 18px',
    overflow: 'auto',
  },

  readOnlyLabel: {
    position: 'absolute',
    top: '-8px',
    left: '10px',
    backgroundColor: theme.palette.background.paper,
    padding: '0 4px',
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
  },

  readOnlyDscripton: {
    minHeight: '50vh',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: 'auto',
    fontFamily: theme.typography.fontFamily,
  },
}));

const ClinicalNotePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid, clinicalNoteId } = useParams();
  const patientUuid = uuid;
  const classes = useStyles();
  const { clinicalImpression } = greyboxApiActions;
  const [updateImpression, updateResult] = clinicalImpression.update();
  const [createImpression, createResult] = clinicalImpression.add();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [currentDialog, setCurrentDialog] = useState('');
  const { accountId } = useSelector((state) => state.user);
  const [showSaved, setShowSaved] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeOpen, setIframeOpen] = useState(false);
  const [documentToPrint, setDocumentToPrint] = useState(null);
  const [openGenerateClinicalReportDialog, setOpenGenerateClinicalReportDialog] = useState(false);
  const [openGeneratedReportMessage, setOpenGeneratedReportMessage] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: '', severity: 'success' });
  const [editingDraft, setEditingDraft] = useState(false);
  const [documentsaved, setDocumentSaved] = useState(true);
  const [initiated, setInitiated] = useState(false);
  const isLoading = createResult.isLoading || updateResult.isLoading || formik?.isSubmitting;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const printRef = useRef();

  const { data, refetch } = clinicalImpression.list({
    subject: `Patient/${uuid}`,
    _sort: '-date',
  });

  const note = data?.entry?.find((entry) => entry.resource.id === clinicalNoteId)?.resource;
  const isNewNote = !note;
  const firstName = note?.assessor?.display?.firstName;
  const lastName = note?.assessor?.display?.lastName;
  const formattedName = firstName ? (isMobile ? `${firstName.charAt(0).toUpperCase()}.` : firstName) : '';
  const lastUpdated = new Date(note?.meta?.lastUpdated).toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  useEffect(() => {
    if (note && (formik.values.summary !== note?.summary || formik.values.description !== note?.description)) {
      formik.setValues({
        summary: note?.summary,
        description: note?.description,
        status: note?.status,
      });
      setDocumentSaved(true);
    }
  }, [note]);

  const debouncedSave = useCallback(
    debounce(() => formik.submitForm(), 5000),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  const formik = useFormik({
    initialValues: {
      summary: isNewNote ? '' : note?.summary,
      description: isNewNote ? '' : note?.description,
      status: isNewNote ? 'in-progress' : note?.status,
    },
    validationSchema: yup.object({
      summary: yup.string().required(t('Summary is required')),
      description: yup.string().required(t('Description is required')),
    }),
    onSubmit: async (values) => {
      try {
        debouncedSave.cancel();
        const body = {
          ...values,
          assessor: {
            reference: `Practitioner/${accountId}`,
          },
        };

        if (isNewNote) {
          body.subject = { reference: `Patient/${patientUuid}` };
          await createImpression({ body });
        } else {
          await updateImpression({ id: note.id, body });
        }

        setEditingDraft(false);
        setShowSaved(true);
        setDocumentSaved(true);

        await delay(1000); // refetch immediately after saving returns the old data, need to wait a bit

        const result = await refetch();
        if (isNewNote && result.data?.entry?.length > 0) {
          const newNoteId = result.data.entry[0].resource.id;
          navigate(`/patient-profile/${patientUuid}/clinical-note/${newNoteId}`);
        }
      } catch (error) {
        console.error('Error saving note:', error);
      }
    },
  });

  const handleFieldChange = ({ name, value }) => {
    formik.setFieldValue(name, value);
    if (initiated) {
      if (!isNewNote) {
        debouncedSave(formik.values);
      }
      setEditingDraft(true);
      setDocumentSaved(false);
    } else {
      setInitiated(true);
    }
  };

  const handleFormAction = async (status, onSuccess) => {
    if (status === 'completed') {
      const markdown = turndownService.turndown(formik.values.description);
      await formik.setFieldValue('description', markdown);
    }
    await formik.setFieldValue('status', status);
    try {
      await formik.submitForm();
      if (onSuccess) {
        await onSuccess();
      }
    } catch (error) {
      console.error(`Error during ${status} action:`, error);
    }
  };

  const handleConfirmDialog = async () => {
    switch (currentDialog) {
      case 'finalize':
        await handleFormAction('completed', refetch);
        break;
      case 'delete':
        await handleFormAction('entered-in-error', () => navigate(`/patient-profile/${patientUuid}/clinical-note`));
        break;
      case 'leave':
        navigate(-1);
        break;
      default:
        break;
    }
    setConfirmationOpen(false);
  };

  const handleOpenIframe = () => {
    if (iframeUrl) {
      setIframeOpen(true);
    }
  };

  const handleCloseIframe = () => {
    setIframeOpen(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: documentToPrint?.summary,
    onAfterPrint: () => setDocumentToPrint(null),
    ignoreGlobalStyles: true,
  });

  useEffect(() => {
    if (documentToPrint) {
      handlePrint();
    }
  }, [documentToPrint, handlePrint]);

  const handleGoBack = () => {
    if (note?.status === 'completed') {
      navigate(`/patient-profile/${patientUuid}/clinical-note`);
    } else if ((formik.dirty || note?.status === 'in-progress') && !documentsaved) {
      setConfirmationOpen(true);
      debouncedSave.cancel();
      setCurrentDialog('leave');
    } else {
      navigate(`/patient-profile/${patientUuid}/clinical-note`);
    }
  };

  const handleCloseGeneratedReportMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenGeneratedReportMessage(false);
  };

  if (!note && clinicalNoteId !== 'create-new-note') {
    return <ClinicalNoteSkeleton />;
  } else {
    return (
      <Container maxWidth="md">
        {isNewNote || note ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <IconButton onClick={handleGoBack} sx={{ mr: 1 }}>
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                className={classes.title}
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {isNewNote ? t('Create note') : note?.status === 'completed' ? t('Clinical Note') : t('Edit note')}
              </Typography>
              {isLoading || note?.status === 'in-progress' ? (
                <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                  {isLoading ? (
                    <>
                      <CircularProgress size={20} />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {t('Saving...')}
                      </Typography>
                    </>
                  ) : editingDraft ? (
                    <>
                      <IoCloudOffline color="grey" size={20} />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {t('Editing draft...')}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <IoCloudDone color="green" size={20} />
                      {showSaved && (
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          {t('Saved')}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              ) : (
                note?.id && (
                  <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                    <IoCloudDone color="green" size={20} />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {note?.status === 'entered-in-error' ? t('Deleted by') : t('Saved by')} {formattedName} {lastName}{' '}
                      {t('at')} {lastUpdated}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
            <Paper className={classes.editorContainer}>
              <form onSubmit={formik.handleSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  {!isNewNote && note?.status === 'completed' ? (
                    <div className={classes.readOnlySummary}>
                      <label className={classes.readOnlyLabel}>{t('Summary')}</label>
                      <ReactMarkdown className={classes.markdown}>{formik.values.summary}</ReactMarkdown>
                    </div>
                  ) : (
                    <TextField
                      label={t('Summary')}
                      id="summary"
                      name="summary"
                      required
                      fullWidth
                      variant="outlined"
                      value={formik.values.summary}
                      onChange={(e) => handleFieldChange(e.target)}
                      onBlur={formik.handleBlur}
                      error={formik.touched.summary && Boolean(formik.errors.summary)}
                      helperText={formik.touched.summary && formik.errors.summary}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                </Box>
                <div className={classes.spacer} style={{ position: 'relative' }}>
                  {!isNewNote && note?.status === 'completed' ? (
                    <div className={classes.readOnlyDscripton} ref={printRef}>
                      <label className={classes.readOnlyLabel}>{t('Description')}</label>
                      <Markdown sx={{ fontFamily: 'Helvetica, Arial, sans-serif' }}>{note?.description}</Markdown>
                    </div>
                  ) : (
                    <Box sx={{ mb: 2 }}>
                      <QuillWrapper
                        label={t('Description*')}
                        formik={formik}
                        defaultValue={note?.description}
                        onChange={(value) => {
                          handleFieldChange({ name: 'description', value });
                        }}
                      />
                    </Box>
                  )}
                </div>
                {note?.status !== 'completed' ? (
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" mt={2}>
                    {!isNewNote && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          // setSavingIndicator(true);
                          setConfirmationOpen(true);
                          debouncedSave.cancel();
                          setCurrentDialog('delete');
                        }}
                        disabled={isLoading}
                        sx={{
                          mr: isMobile ? 0 : 1,
                          borderColor: 'warning.dark',
                          color: 'warning.dark',
                          '&:hover': {
                            borderColor: 'warning.light',
                            color: 'warning.light',
                          },
                        }}
                        style={{ cursor: isLoading ? 'default' : 'pointer' }}
                      >
                        {t('Delete Draft')}
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setDocumentSaved(true);
                        setEditingDraft(false);
                        formik.setFieldValue('status', 'in-progress');
                        formik.submitForm();
                        debouncedSave.cancel();
                      }}
                      disabled={isLoading || !formik.values.summary || !formik.values.description}
                      sx={{
                        mr: isMobile ? 0 : 1,
                        my: isMobile ? 1 : 0,
                        borderColor: 'secondary.main',
                        color: 'secondary.main',
                        '&:hover': {
                          borderColor: 'primary.main',
                          color: 'primary.main',
                        },
                      }}
                      style={{ cursor: isLoading ? 'default' : 'pointer' }}
                    >
                      {t('Save Draft')}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        // setSavingIndicator(true);
                        setConfirmationOpen(true);
                        setCurrentDialog('finalize');
                        debouncedSave.cancel();
                      }}
                      disabled={isLoading || !formik.values.summary || !formik.values.description}
                      sx={{
                        cursor: isLoading ? 'default' : 'pointer',
                      }}
                    >
                      {t('Finalize')}
                    </Button>
                  </Box>
                ) : (
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" mt={2}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setDocumentToPrint(note);
                      }}
                      disabled={isLoading}
                      sx={{
                        mr: isMobile ? 0 : 1,
                        my: isMobile ? 1 : 0,
                        borderColor: 'primary.main',
                        color: 'primary.main',
                      }}
                      style={{ cursor: isLoading ? 'default' : 'pointer' }}
                    >
                      {t('Print')}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenGenerateClinicalReportDialog(true);
                      }}
                      disabled={isLoading}
                      sx={{
                        cursor: isLoading ? 'default' : 'pointer',
                      }}
                    >
                      {t('Generate report')}
                    </Button>
                  </Box>
                )}
              </form>
              {iframeUrl && (
                <Box display="flex" justifyContent="end" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenIframe}
                    style={{ textTransform: 'none' }}
                  >
                    {t('View Clinical Report PDF')}
                  </Button>
                </Box>
              )}

              <Dialog
                open={iframeOpen}
                onClose={handleCloseIframe}
                fullWidth
                maxWidth="md"
                PaperProps={{ style: { height: '100%' } }}
              >
                <DialogContent style={{ height: '100%' }}>
                  <iframe
                    src={iframeUrl}
                    width="100%"
                    height="100%"
                    title="Report Preview"
                    style={{ border: 'none' }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseIframe}>Close</Button>
                </DialogActions>
              </Dialog>
            </Paper>
            <ConfirmationDialog
              open={confirmationOpen}
              currentDialog={currentDialog}
              handleConfirmDialog={handleConfirmDialog}
              handleCloseDialog={() => setConfirmationOpen(false)}
              spinnerVisible={isLoading}
            />
            <Box sx={{ display: 'none' }}>
              <PrintableNote ref={printRef} note={documentToPrint} />
            </Box>
            <Snackbar
              open={openGeneratedReportMessage}
              autoHideDuration={6000}
              onClose={handleCloseGeneratedReportMessage}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handleCloseGeneratedReportMessage}
                severity={snackbar.severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
            <GenerateClinicalNoteReportDialog
              open={openGenerateClinicalReportDialog}
              handleClose={() => setOpenGenerateClinicalReportDialog(false)}
              resource={note}
              patientUuid={uuid}
              setOpenGeneratedReportMessage={setOpenGeneratedReportMessage}
              setSnackbar={setSnackbar}
              setIframeUrl={setIframeUrl}
            />
          </>
        ) : null}
      </Container>
    );
  }
};

export default ClinicalNotePage;
